.main_div_form_get_in_touch {
  /* background-image: url("./../../Assets/form_bg_homepage.png"); */

  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 100%;
  height: 831px;
}

.white_side_form_main_div {
  width: 100%;
  height: 631px;
  flex-shrink: 0;
  background: #fff;
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.1);
  padding: 5%;
  margin: 0;
  margin-top: 13%;
  overflow: hidden;
}

.para_in_form {
  color: #232323;
  font-family: "Poppins", sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 800;
  line-height: 36px; /* 120% */
}
.g-recaptcha {
  margin-top: 3%;
}
.input_style_name_form {
  color: #6d6d6d;
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 30px;
  height: 50px;
  width: 100%;
  border: 1px solid rgba(35, 35, 35, 0.12);
  background: #f7f4f4;
  padding: 16px 26px 16px 26px;
  outline: none;
  margin-top: 6%;
}

.textarea_form_get_in_touch {
  width: 100%;
  height: 135px;
  border-radius: 30px;
  border: 1px solid rgba(35, 35, 35, 0.12);
  background: #f7f4f4;
  margin-top: 5%;
  outline: none;
  padding: 16px 26px 16px 26px;
  color: #6d6d6d;
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.btn_submit{
  width: 100%;
  text-align: end;
}
.button_style_form_get_in_touch {
  border-radius: 0px;
  background: #FF5E15;
  padding-left: 20px;
  padding-right: 20px;
  /* width: 100%; */
  height: 55px;
  text-align: center;
  color: #fff;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  margin-top: 5%;
}

.blue_side_form_main_div {
  width: 100%;
  height: 631px;
  flex-shrink: 0;
  background: #1d4480;
  padding: 7%;
  margin: 0;
  margin-top: 13%;
  overflow: hidden;
}
.location_heading_form {
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 120% */
}
.para_in_location_form {
  color: rgba(255, 255, 255, 0.74);
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px; /* 162.5% */
}

@media only screen and (max-width: 991px) {
  .main_div_form_get_in_touch {
    /* background-image: url("./../../Assets/form_bg_homepage.png"); */

    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 100%;
    height: auto;
    padding-bottom: 10%;
  }
  .blue_side_form_main_div {
    width: 100%;
    height: 631px;
    flex-shrink: 0;
    background: #1d4480;
    padding: 7%;
    margin: 0;
    margin-top: 0%;
    overflow: hidden;
  }
}

@media only screen and (max-width: 500px) {
  .para_in_form {
    color: #232323;
    font-family: "Poppins", sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px; /* 120% */
  }

  .input_style_name_form {
    color: #6d6d6d;
    font-family: "Rubik", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 30px;
    height: 50px;
    width: 100%;
    border: 1px solid rgba(35, 35, 35, 0.12);
    background: #f7f4f4;
    padding: 16px 26px 16px 26px;
    outline: none;
    margin-top: 6%;
  }

  .textarea_form_get_in_touch {
    width: 100%;
    height: 135px;
    border-radius: 30px;
    border: 1px solid rgba(35, 35, 35, 0.12);
    background: #f7f4f4;
    margin-top: 5%;
    outline: none;
    padding: 16px 26px 16px 26px;
    color: #6d6d6d;
    font-family: "Rubik", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .button_style_form_get_in_touch {
    border-radius: 0px;
    background: #FF5E15;
    /* width: 100%; */
    padding-left: 20px;
    padding-right: 20px;
    height: 55px;

    text-align: center;
    color: #fff;
    text-align: center;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    margin-top: 5%;
  }

  .location_heading_form {
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 120% */
  }
  .para_in_location_form {
    color: rgba(255, 255, 255, 0.74);
    font-family: "Rubik", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 15px; /* 162.5% */
  }
  .email_style_icon_form {
    width: 40px;
    height: 35px;
  }
}
