/* 3D Slideshow */ 
/* * {
	margin: 0;
	padding: 0;
}

html, body {
	max-width: 100%;
	overflow-x: hidden;
	overflow-y: hidden;
	background-color: lightcoral;
} */


#slideshow {
	margin: 0 auto;
	padding-top: 50px;
	height: 600px;
	width: 100%;
	box-sizing: border-box;
  background-image: url("../../Assets/services_back.png");
}

.slideshow-title {
	font-family: 'Allerta Stencil';
	font-size: 62px;
	color: #fff;
	margin: 0 auto;
	text-align: center;
	margin-top: 25%;
	letter-spacing: 3px;
	font-weight: 300;
}

.sub-heading {
	padding-top: 50px;
	font-size: 18px;
} .sub-heading-two {
	font-size: 15px;
} .sub-heading-three {
	font-size: 13px;
} .sub-heading-four {
	font-size: 11px;
} .sub-heading-five {
	font-size: 9px;
} .sub-heading-six {
	font-size: 7px;
} .sub-heading-seven {
	font-size: 5px;
} .sub-heading-eight {
	font-size: 3px;
} .sub-heading-nine {
	font-size: 1px;
}

.entire-content {
	margin: auto;
	width: 190px;
	perspective: 1000px;
	position: relative;
	padding-top: 80px;
}

.content-carrousel {
	width: 100%;
	position: absolute;
	float: right;
	animation: rotar 45s infinite linear;
	transform-style: preserve-3d;
}

.content-carrousel:hover {
	animation-play-state: paused;
	cursor: pointer;
}

/* .content-carrousel figure {
	width: 100%;
	height: 120px;
	border: 1px solid #3b444b;
	overflow: hidden;
	position: absolute;
} */

.content-carrousel figure:nth-child(1) {
	transform: rotateY(0deg) translateZ(300px); 
} .content-carrousel figure:nth-child(2) {
	transform: rotateY(40deg) translateZ(300px); 
} .content-carrousel figure:nth-child(3) {
	transform: rotateY(80deg) translateZ(300px); 
} .content-carrousel figure:nth-child(4) {
	transform: rotateY(120deg) translateZ(300px); 
} .content-carrousel figure:nth-child(5) {
	transform: rotateY(160deg) translateZ(300px); 
} .content-carrousel figure:nth-child(6) {
	transform: rotateY(200deg) translateZ(300px); 
} .content-carrousel figure:nth-child(7) {
	transform: rotateY(240deg) translateZ(300px); 
} .content-carrousel figure:nth-child(8) {
	transform: rotateY(280deg) translateZ(300px); 
} .content-carrousel figure:nth-child(9) {
	transform: rotateY(320deg) translateZ(300px); 
} .content-carrousel figure:nth-child(10) {
	transform: rotateY(360deg) translateZ(300px); 
} 

.shadow1 {
    position: absolute;
	box-shadow: none !important;
    /* box-shadow: 0px 0px 20px 0px #000; */
    border-radius: 1px;
}

.content-carrousel img {
	image-rendering: auto;
	transition: all 300ms;
	width: 100%;
	height: 100%;
}

.content-carrousel img:hover {
	transform: scale(1.2);
	transition: all 300ms;
}

@keyframes rotar {
	from {
		transform: rotateY(0deg);
	} to {
		transform: rotateY(360deg);
	}
}


@media (max-width: 1280px) {
	#slideshow {
	  /* Adjust styles for smaller screens */
	  height: 400px; /* Adjust the height for smaller screens */
	}
  
	.slideshow-title {
	  /* Adjust styles for smaller screens */
	  font-size: 40px; /* Adjust the font size for smaller screens */
	}
  
	.entire-content {
	  /* Adjust styles for smaller screens */
	  width: 150px; /* Adjust the width for smaller screens */
	}
  
	.content-carrousel figure {
	  /* Adjust styles for smaller screens */
	  width: 80px; /* Adjust the width for smaller screens */
	  height: 80px; /* Adjust the height for smaller screens */
	}
  
	.content-carrousel img {
	  /* Adjust styles for smaller screens */
	  width: 100%; /* Maintain image width for smaller screens */
	  height: 100%; /* Maintain image height for smaller screens */
	}
  }

  @media (max-width: 786px) {
	#slideshow {
	  /* Further adjustments for screens less than 786px */
	  height: 300px; /* Adjust the height for smaller screens */
	}
  
	.slideshow-title {
	  /* Further adjustments for screens less than 786px */
	  font-size: 30px; /* Adjust the font size for smaller screens */
	}
  
	.entire-content {
	  /* Further adjustments for screens less than 786px */
	  width: 120px; /* Adjust the width for smaller screens */
	  padding-top: 50px; /* Adjust the padding for smaller screens */
	}
  
	.content-carrousel figure {
	  /* Further adjustments for screens less than 786px */
	  width: 60px; /* Adjust the width for smaller screens */
	  height: 60px; /* Adjust the height for smaller screens */
	}
  }
  